import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next' // Import useTranslation
import './Css/Mahsulotlar.css' // Keep your custom CSS if needed

function Mahsulotlar() {
  const { t } = useTranslation() // Use translation hook
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0)
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart')
    return savedCart ? JSON.parse(savedCart) : []
  })
  const language = localStorage.getItem('language') || 'uz'

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          'https://qizildasturchi.uz/api/categories',
          {
            headers: {
              'X-App-Language': language,
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const result = await response.json()
        setCategories(result.data)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchCategories()
  }, [language])

  const handleClick = (index) => {
    setActiveIndex(index)
  }

  const handleAddToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id)
      const updatedCart = existingProduct
        ? prevCart.map((item) =>
            item.id === product.id ? { ...item, count: item.count + 1 } : item
          )
        : [...prevCart, { ...product, count: 1 }]

      localStorage.setItem('cart', JSON.stringify(updatedCart))
      navigate('/savatcha')
      return updatedCart
    })
  }

  if (loading) return <div>{t('loading')}</div>
  if (error) return <div>{t('error', { message: error })}</div>

  return (
    <div className="flex mahsulot-wrapper flex-col md:flex-row h-screen bg-gray-100">
      <aside className="w-full md:w-1/4 bg-white shadow-lg p-4 mb-4 md:mb-0">
        <h2 className="text-xl font-bold text-gray-800 mb-6">
          {t('categories')}
        </h2>
        <div className="space-y-2">
          {categories.map((category, index) => (
            <div
              key={category.id}
              className={`flex items-center p-2 rounded-md text-sm cursor-pointer transition-colors duration-300 ${
                activeIndex === index
                  ? 'bg-green-500 text-white shadow'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
              onClick={() => handleClick(index)}
            >
              <h3>{category.name}</h3>
            </div>
          ))}
        </div>
      </aside>

      <main className="flex-1 p-2 md:p-6">
        {activeIndex !== null && categories[activeIndex] ? (
          <div className="grid relative grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {categories[activeIndex].products?.length > 0 ? (
              categories[activeIndex].products.map((product) => (
                <div
                  key={product.id}
                  className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                >
                  <img
                    src={
                      product.image?.startsWith(
                        'https://qizildasturchi.uz/image'
                      )
                        ? product.image
                        : `https://qizildasturchi.uz/image/${product.image}`
                    }
                    alt={product.translations.name}
                    className="w-full h-40 object-contain mb-4 rounded-lg"
                  />
                  <p className="mb-2">
                    {t('productName')} {product.translations.name}
                  </p>
                  <p className="mb-2">
                    {t('productDescription')} {product.translations.description}
                  </p>
                  <p className="text-gray-600 mb-1">
                    {t('productPrice')} {product.price.toLocaleString()} so'm
                  </p>
                  <p className="text-gray-500 mb-4">
                    {t('productCount')} {product.count} ta
                  </p>
                  <button
                    className="bg-green-500 text-white w-full py-2 rounded-md hover:bg-green-600 transition-colors duration-300"
                    onClick={() => handleAddToCart(product)}
                  >
                    {t('addToCart')}
                  </button>
                </div>
              ))
            ) : (
              <p className="text-center absolute-text col-span-full text-gray-500">
                {t('productDontExist')}
              </p>
            )}
          </div>
        ) : (
          <p className="text-center text-gray-500"></p>
        )}
      </main>

      <Link
        to="/savatcha"
        className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-transform transform hover:scale-105"
      >
        {t('goToCart')}
      </Link>
    </div>
  )
}

export default Mahsulotlar
