import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next' // i18next'dan olingan
import './Css/Cart.css'
import '../index.css'
import cartIcon from '../Components/Rasmlar/360_F_560176615_cUua21qgzxDiLiiyiVGYjUnLSGnVLIi6.jpg'

function Cart() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const language = localStorage.getItem('language') || 'uz'

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://qizildasturchi.uz/api/products', {
          headers: {
            'X-App-Language': language,
          },
        })

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        const result = await response.json()
        setProducts(result.data.records)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchProducts()
  }, [language])

  const handleCartClick = (product) => {
    const currentCart = JSON.parse(localStorage.getItem('cart')) || []
    const existingProduct = currentCart.find((item) => item.id === product.id)
    let updatedCart

    if (existingProduct) {
      updatedCart = currentCart.map((item) =>
        item.id === product.id ? { ...item, count: item.count + 1 } : item
      )
    } else {
      updatedCart = [...currentCart, { ...product, count: 1 }]
    }

    localStorage.setItem('cart', JSON.stringify(updatedCart))
    navigate('/savatcha')
  }

  if (loading) return <div>{t('loading')}</div>
  if (error)
    return (
      <div>
        {t('error')}: {error}
      </div>
    )

  return (
    <div className="Cart">
      <div className="search-col">
        <div className="h2">
          <h2>{t('cart.searchTitle')}</h2>
        </div>
        <div className="search">
          <input
            className="input"
            type="search"
            placeholder={t('cart.searchPlaceholder')}
          />
          <button className="button">{t('cart.searchButton')}</button>
        </div>
      </div>
      <div className="products">
        {products.map((product) => (
          <div className="product1" key={product.id}>
            <div className="">
              <img
                className="product-img"
                src={
                  product.image?.startsWith('https://qizildasturchi.uz/image')
                    ? product.image
                    : `https://qizildasturchi.uz/image/${product.image}`
                }
                alt={product.name}
              />
            </div>
            <div className="t">
              <h2>
                {t('cart.name')}: {product.name} <br />
                {t('cart.description')}: {product.description} <br />
                {t('cart.price')}: {product.price?.toLocaleString()}{' '}
                {t('cart.currency')}
              </h2>
              <img
                className="carticon"
                src={cartIcon}
                alt="Cart Icon"
                onClick={() => handleCartClick(product)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="hr2"></div>
    </div>
  )
}

export default Cart
