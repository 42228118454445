import React, { useState } from 'react'
import { useTranslation } from 'react-i18next' // Import useTranslation
import './Css/Hisobim.css'
import './Css/Royhatdan_otish.css'

function Hisobim() {
  const { t } = useTranslation() // Get the translation function
  const [fullName, setFullName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleRegister = async () => {
    setErrorMessage('')
    setLoading(true)

    if (!phoneNumber || !password) {
      setErrorMessage(t('enterPhoneAndPassword'))
      setLoading(false)
      return
    }

    try {
      const registerResponse = await fetch(
        'https://qizildasturchi.uz/api/auth/register',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            full_name: fullName,
            phone_number: phoneNumber,
            password,
          }),
        }
      )

      if (registerResponse.ok) {
        const registerData = await registerResponse.json()
        alert(t('registrationSuccess'))
        localStorage.setItem('userToken', registerData.data.token)
        localStorage.setItem('userId', registerData.data.data.id)
        window.location.href = '/'
      } else if (registerResponse.status === 400) {
        const loginResponse = await fetch(
          'https://qizildasturchi.uz/api/auth/login',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone_number: phoneNumber, password }),
          }
        )

        const loginData = await loginResponse.json()
        if (loginData.success) {
          alert(t('loginSuccess'))
          localStorage.setItem('userToken', loginData.data.token)
          localStorage.setItem('userId', loginData.data.data.id)
          window.location.href = '/'
        } else {
          setErrorMessage(t('phoneOrPasswordError'))
        }
      } else {
        setErrorMessage(t('registrationError'))
      }
    } catch (error) {
      console.error('Error:', error)
      setErrorMessage(t('dataError'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="hisobim">
      <h2 className="h2R">{t('registrationTitle')}</h2>
      <div className="login10">
        <div className="input10">
          <input
            className="input"
            type="text"
            placeholder={t('enterName')}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <input
            className="input"
            type="text"
            placeholder={t('enterPhone')}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <input
            className="input"
            type="password"
            placeholder={t('enterPassword')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="btn">
          <button
            className="button1"
            onClick={handleRegister}
            disabled={loading}
          >
            {loading ? t('loading') : t('confirm')}
          </button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
      <div className="h3"></div>
    </div>
  )
}

export default Hisobim
