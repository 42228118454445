import React from 'react'
import Cart from './Cart'

function Boshsahifa() {
  return (
    <div>
      <Cart/>
    </div>
  )
}

export default Boshsahifa