import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next' // useTranslation ni import qiling
import './Navbar.css'
import logo from '../Rasmlar/logo.jpg'

const Navbar = () => {
  const { t, i18n } = useTranslation() // useTranslation ni qo'llash
  const [menu, setMenu] = useState('Bosh sahifa')
  const [isMenuActive, setIsMenuActive] = useState(false)
  const isAuthenticated = !!localStorage.getItem('userToken')

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive)
  }

  const [language, setLanguage] = useState(
    localStorage.getItem('language') || 'uz'
  )
  useEffect(() => {
    localStorage.setItem('language', language)
    i18n.changeLanguage(language)
  }, [language, i18n])

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value
    localStorage.setItem('language', newLanguage)
    setLanguage(newLanguage)
  }

  return (
    <div className="">
      <div className="Navbar">
        <div className="flex-justify">
          <div className="nav-logo">
            <img src={logo} alt="Logo" />
            <p>{t('navbar.title')}</p> {/* Tarjima funksiyasi bilan matn */}
          </div>
          <div className="menu-toggle" onClick={toggleMenu}>
            {isMenuActive ? '✖️' : '☰'}
          </div>
        </div>
        <ul className={`nav_menu ${isMenuActive ? 'active' : ''}`}>
          <li
            className={menu === 'Bosh sahifa' ? 'active' : ''}
            onClick={() => setMenu('Bosh sahifa')}
          >
            <Link to="/" className="nav-link">
              {t('navbar.home')} {/* Tarjima */}
            </Link>
          </li>
          <div className="savat">
            <li
              className={menu === 'Savatcha' ? 'active' : ''}
              onClick={() => setMenu('Savatcha')}
            >
              <Link to="/savatcha" className="nav-link">
                {t('navbar.cart')} {/* Tarjima */}
              </Link>
            </li>
            <div className="nav-cart-count">
              <h2>{JSON.parse(localStorage.getItem('cart'))?.length || 0}</h2>
            </div>
          </div>
          <li
            className={menu === 'Mahsulot Turlari' ? 'active' : ''}
            onClick={() => setMenu('Mahsulot Turlari')}
          >
            <Link to="/mahsulot-turlari" className="nav-link">
              {t('navbar.productTypes')} {/* Tarjima */}
            </Link>
          </li>
          <li
            className={menu === 'Buyurtmalar' ? 'active' : ''}
            onClick={() => setMenu('Buyurtmalar')}
          >
            <Link to="/buyurtmalar" className="nav-link">
              {t('navbar.orders')} {/* Tarjima */}
            </Link>
          </li>
          <li
            className={
              menu === (isAuthenticated ? 'Profil' : 'Hisobim') ? 'active' : ''
            }
            onClick={() => setMenu(isAuthenticated ? 'Profil' : 'Hisobim')}
          >
            <Link to="/hisobim" className="nav-link">
              {isAuthenticated ? t('navbar.profile') : t('navbar.account')}{' '}
              {/* Tarjima */}
            </Link>
          </li>
          <div className="ms-auto">
            <select
              className="form-select"
              value={language}
              onChange={handleLanguageChange}
            >
              <option value="uz">Uz</option>
              <option value="ru">Ru</option>
              <option value="en">En</option>
            </select>
          </div>
        </ul>
      </div>
      <div className="hr"></div>
    </div>
  )
}

export default Navbar
