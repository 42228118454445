import React from 'react'

function Product() {
  return (
    <div>
        
    </div>
  )
}

export default Product